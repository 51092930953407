<template>
	<div>
		<!-- <el-upload class="avatar-uploader" action :show-file-list="false" :http-request="selectPicUpload"
			:before-upload="beforeAvatarUpload1" :data="{ token: UserToken }" :headers="{ token: UserToken }"  >
			<img v-if="imageUrl" width="200px" height="200px" :src="imageUrl" class="avatar" />
			<i v-else class="el-icon-plus avatar-uploader-icon"></i>
		</el-upload> -->

		<el-form :inline="true">
			<el-form-item>
				<el-button type="primary" @click="handle('add',null)">新增账号</el-button>
				<el-button type="success" @click="handlePass('pass')">修改后台密码</el-button>
			</el-form-item>
		</el-form>
	
		<el-table :data="tableData" border style="width: 100%" height="600"
			:header-cell-style="{ background: '#eef1f6', color: '#606266' }">
			<el-table-column prop="id" label="ID" width="90"> </el-table-column>

			<el-table-column prop="phone" label="手机号"> </el-table-column>
			<el-table-column prop="nickname" label="名称"> </el-table-column>

			<el-table-column label="编辑" width="368">
				<template slot-scope="scope">
					<el-button type="primary" icon="el-icon-edit" size="mini" @click="handle('edit',scope.row)">编辑
					</el-button>
					<el-button type="success" icon="el-icon-edit" size="mini" @click="handle('message',scope.row)">科目
					</el-button>
					<el-button type="danger" icon="el-icon-delete" size="mini" @click="deleteBtn(scope.row)">删除
					</el-button>
					<el-button type="info" icon="el-icon-notebook-1" size="mini" @click="handle('make',scope.row)">预约信息
					</el-button>
				</template>
			</el-table-column>
		</el-table>
		<el-dialog title="信息配置" :visible.sync="isShowForm">
			<el-form :model="user" :inline="true" v-if="type=='add'||type=='edit'">
				<el-form-item label="手机号">
					<el-input v-model="user.phone" :max="11" :disabled="type=='add'?false:true" autocomplete="off" placeholder="手机号码"></el-input>
				</el-form-item>
				<el-form-item label="密码">
					<el-input v-model="user.password"  autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item label="地区">
					<el-input v-model="user.address" autocomplete="off" placeholder="请输入地区"></el-input>
				</el-form-item>
				<el-form-item label="昵称">
					<el-input v-model="user.nickname" autocomplete="off" placeholder="请输入昵称"></el-input>
				</el-form-item>
				<el-form-item label="证件号码">
					<el-input v-model="user.certificate_no" autocomplete="off" placeholder="请输入证件号"></el-input>
				</el-form-item>

				<el-form-item label="考试车型">
					<el-input v-model="user.car_model" autocomplete="off" placeholder="请输入考试车型"></el-input>
				</el-form-item>
				<el-form-item label="考试原因">
					<el-input v-model="user.exam_reason" autocomplete="off" placeholder="请输入考试原因"></el-input>
				</el-form-item>
				<el-form-item label="实人认证">
					<el-input v-model="user.id_card" autocomplete="off" placeholder="请输入实人认证"></el-input>
				</el-form-item>
				<el-form-item label="我的证件">
					<el-input v-model="user.certificate" autocomplete="off" placeholder="请输入我的证件"></el-input>
				</el-form-item>
				<el-form-item label="头像">
					<el-upload class="avatar-uploader" :action="action" :show-file-list="false"
						name="avatar" :headers="{ Authorization: UserToken }" :on-success="handleAvatarSuccess"
						:before-upload="beforeAvatarUpload">
						<img v-if="true" :src="imageUrl" class="avatarImg" />
						<i v-else class="el-icon-plus avatar-uploader-icon"></i>
					</el-upload>
				<!-- 	<el-upload class="avatar-uploader" action :show-file-list="false" :http-request="selectPicUpload"
						:before-upload="beforeAvatarUpload" :data="{ token: UserToken }" :headers="{ token: UserToken }"  >
						<img v-if="true" :src="imageUrl" class="avatarImg" />
						<i v-else class="el-icon-plus avatar-uploader-icon"></i>
					</el-upload> -->
					
				</el-form-item>
			</el-form>
			<el-form :inline="true" v-if="type=='message'" >
				<el-row v-for="(item, index) in messageData" :key="index">
					<el-col :span="10">
						<el-form-item :label="item.sub_name">
							<el-input v-model="item.sub_time" placeholder="考试时间"></el-input>
						</el-form-item>
					</el-col>

					<el-col :span="10">
						<el-form-item label="成绩" >
							<el-input v-model="item.sub_score" placeholder="成绩"></el-input>
						</el-form-item>
					</el-col>

					<el-col :span="4">
						<el-form-item label="显示">
							<el-switch v-model="item.change"></el-switch>
						</el-form-item>
					</el-col>
				</el-row>
			</el-form>
			<el-form label-width="70px" v-if="type=='make'">
				<el-form-item label="预约名称">
					<el-input v-model="makeFrom.subject_type" autocomplete="off" placeholder="科目一"></el-input>
				</el-form-item>
				<el-form-item label="预约状态">
					<el-input v-model="makeFrom.status" autocomplete="off" placeholder="成功预约"></el-input>
				</el-form-item>
				<el-form-item label="考试场地">
					<el-input v-model="makeFrom.address" autocomplete="off" placeholder="北京市北京大道888号"></el-input>
				</el-form-item>
				<el-form-item label="时间">
					<el-input v-model="makeFrom.exam_time" autocomplete="off" placeholder="2009-09-19"></el-input>
				</el-form-item>
				<el-form-item label="显示">
					<el-switch v-model="makeFrom.change"></el-switch>
				</el-form-item>
				
			</el-form>
			<el-form label-width="70px" v-if="type=='pass'">
				<el-form-item label="后台密码">
					<el-input v-model="password" autocomplete="off" placeholder="后台密码"></el-input>
				</el-form-item>
				
				
			</el-form>

			<div slot="footer" class="dialog-footer">
				<el-button @click="isShowForm = false">取 消</el-button>
				<el-button type="primary" @click="resBtn">确 定</el-button>
			</div>
		</el-dialog>


	
	</div>
</template>

<script>
	import {
		mapState
	} from "vuex";
	import {
		userInfo,
		manageAdd,
		getList,
		getMake,
		addSubject,
		getSubject,
		deleteItem,
		editPass
	} from "../../network/home";
	export default {
		data() {
			return {
				password:"",
				action:"http://wu.12123jg.com.cn/admin.php/api/admin/user-manage/upload-avatar",
				// action: "/api/admin/user-manage/upload-avatar",
				imageUrl: " ",
				isShowForm: false,
				type:"add",
				currentItme:null,
				makeFrom:{
					subject_type:"",
					status:"", //1 预约中 2预约成功
					address:"",//考试场地
					exam_time:"",//考试时间
					change: false,
				},

				deleteChange: false,
				messageData: [{
					sub_name: "科目一",
						sub_time: "2022-3-24",
						sub_score: 98,
						change: false,
						
					},
					{
						sub_name: "科目二",
						sub_time: "2022-5-24",
						sub_score: 98,
						change: false,
					},
					{
						sub_name: "科目三",
						sub_time: "2022-7-24",
						sub_score: 98,
						change: false,
					},
					{
						sub_name: "科目四",
						sub_time: "2022-8-24",
						sub_score: 98,
						change: false,
					},
				],

				user: {
					nickname: "测试11",
					password: "",
					phone: "",
					certificate_no: "",
					exam_reason: "", //考试原因
					car_model: "", //考试车型
					id_card: "", //实人认证
					area: "", //地区
					effective_tiem: "", //有效时间
					certificate: "", //证件类型
					avatar:"",
					address:""
					
				},
			
				tableData: [{id:1}],
			};
		},
		filters: {},
		async created() {
			this.load();

			
		},
		computed: {
			...mapState(["UserToken"]),
		},

		methods: {
			//上传图标事件
			async selectPicUpload  (obj) {
				console.log(obj);
				let fd = new FormData(); //参数的格式是formData格式的
				fd.append("file",obj.file); //参数
				fd.append("token",this.UserToken); 
				
				var res = await upload(fd);
				console.log(res);
				
				this.$message.error(res.msg);
			
			},
			

			//对上传图片的大小、格式进行限制
			beforeAvatarUpload1(file) {
				console.log(file);
				const isJPG = file.type === "image/jpeg";
				const isJPG2 = file.type === "image/jpg";
				const isPNG = file.type === "image/png";
				const isLt5M = file.size / 1024 / 1024 < 5;
				if (!isJPG && !isJPG2 && !isPNG) {
					this.$message.warning("只支持jpg或png格式图片");
				}
				if (!isLt5M) {
					this.$message.warning("请上传5MB以内的图片!");
				}
				return (isJPG || isJPG2 || isPNG) && isLt5M;
			},
			
			
			async handle(type,item) {
				
				
				if(type=='add'){
					this.user= {
						nickname: "",
						password: "",
						phone: "",
						certificate_no: "",
						exam_reason: "", //考试原因
						car_model: "", //考试车型
						id_card: "", //实人认证
						area: "", //地区
						effective_tiem: "", //有效时间
						certificate: "", //证件类型
						avatar:"",
						address:""
						
					}
					this.imageUrl = ''
				}else{
					item.password=""
					// item.avatar = item.avatar
					this.imageUrl =  'http://wu.12123jg.com.cn' +item.avatar
					this.currentItme=item
					
					this.user=JSON.parse(JSON.stringify(item))
				}
				
				this.type=type
				this.isShowForm = true;
				if(this.type=='make'){
					const res =await getMake({
						user_id:this.currentItme.id
					})
					console.log(res,"***");
					if(res.data.length!==0){
						this.makeFrom.subject_type=res.data[0].subject_type
						this.makeFrom.status=res.data[0].status
						this.makeFrom.address=res.data[0].address
						this.makeFrom.exam_time=res.data[0].exam_time
						this.makeFrom.id=res.data[0].id
						
						console.log(res.data[0]);
						if(res.data[0].is_show==0){
							this.makeFrom.change=false
						}else{
							this.makeFrom.change=true
						}
						
					}else{
						this.makeFrom={
							subject_type:"",
							status:"", //1 预约中 2预约成功
							address:"",//考试场地
							exam_time:"",//考试时间
							change:false
						}
					}
					
					// console.log(res);
				}
				if(this.type=='message'){
					
					const res =await getSubject({
						
						user_id:this.currentItme.id
					})
					
					if(!res.data.score_info){
						
						this.messageData=[{
								sub_name: "科目一",
								sub_time: "",
								sub_score: 98,
								is_show: false,
								
							},
							{
								sub_name: "科目二",
								sub_time: "",
								sub_score: 98,
								is_show: false,
							},
							{
								sub_name: "科目三",
								sub_time: "",
								sub_score: 98,
								is_show: false,
							},
							{
								sub_name: "科目四",
								sub_time: "",
								sub_score: 98,
								is_show: false,
							},
						]
					}else{
						this.messageData=JSON.parse(res.data.score_info)
						this.messageData[0].id=res.data.id
						
					}
					console.log(this.messageData);
					
				}
				
				
				// this.user = {};
				
				
			},
			handlePass(type){
				this.type=type
				this.isShowForm = true;
			},
			// 
			 async resBtn(){
				this.isShowForm=false
				
				if(this.type=='add'){
					
					const res = await userInfo(this.user)
					// console.log(res);
					if(res){
						this.$message.success('新增成功');
						this.load()
					}else{
						this.$message.error('信息有重复');
					}
				}
				if(this.type=='edit'){
					
					// delete this.user.phone
					const res = await userInfo({
						...this.user,
						user_id:this.currentItme.id
					})
					console.log(res);
					if(res){
						this.load()
						this.$message.success('保存成功');
					}else{
						this.$message.error('保存失败');
					}
				}
				if(this.type=='message'){
					let data={
						score_info:JSON.stringify(this.messageData),
						user_id:this.currentItme.id
					}
					// console.log(this.messageData[0].id);
					if(this.messageData[0].id){
						data.id=this.messageData[0].id
					}
					const res =await addSubject(data)
					if(res){
						this.$message.success('保存成功');
					}else{
						this.$message.error('保存失败');
					}
					// console.log(JSON.stringify(this.messageData));
					
				}
				// console.log(this.type);
				if(this.type=='make'){	
					console.log(this.makeFrom.change,this.makeFrom.change?1:0);
					const res =await manageAdd({
						...this.makeFrom,
						is_show:this.makeFrom.change?1:0,
						user_id:this.currentItme.id
					})
					if(res){
						this.$message.success('保存成功');
					}else{
						this.$message.error('保存失败');
					}
					console.log(this.makeFrom);
				}
				if(this.type=='pass'){
					
					const res =await editPass({
						password:this.password
					})
					if(res.data){
						this.$message.success('修改成功,请重新登录');
					}
					// console.log(res);
				}
				

			},
			

			async load() {
				var data = {
					page:1,
					page_rows:1000,
					
				};
				var res = await getList(data);
				console.log(res);
				this.tableData = res.data.list;
			},
		
			async deleteBtn(e) {
				console.log(e);
				var that=this
				this.$alert('是否删除', '提示', {
				          confirmButtonText: '确定',
				          callback: action => {
							  console.log(action);
							  if(action==='confirm'){
								  that.deleteUser(e)
							  }
				          
				          }
				        });
				
			},
			async deleteUser(e){
				var res = await deleteItem({
					user_id:e.id
				});
				console.log(res);
				
				if (res.code == 200) {
					this.$message('删除成功');
					this.load();
				}
			},
			messageEdit(e) {
				console.log(e.exam_subject);
					this.messageData = [{
							sub_name: "科目一",
							sub_time: "2022-3-24",
							sub_score: 98,
							change: false,
						},
						{
							sub_name: "科目二",
							sub_time: "2022-5-24",
							sub_score: 98,
							change: false,
						},
						{
							sub_name: "科目三",
							sub_time: "2022-7-24",
							sub_score: 98,
							change: false,
						},
						{
							sub_name: "科目四",
							sub_time: "2022-8-24",
							sub_score: 98,
							change: false,
						},
					];
				
				

			},
			async editRes() {
				//  this.id
				this.messageData.forEach((item) => {
					if (item.change) {
						item.is_show = 1;
					} else {
						item.is_show = 0;
					}
				});
				var data = {
					token: this.UserToken,
					id: this.id,
					exam_subject: JSON.stringify(this.messageData),
				};

				var res = await setsubject(data);
				if (res.code == 1) {
					this.$message.success(res.msg);
				} else {
					this.$message.error(res.msg);
				}
				this.message = false;
				this.id = 0;
				location.reload()
				console.log(res);
			},

			handleAvatarSuccess(res, file) {
				console.log(res);
				
				if (res.code == 200) {
					// this.user.avatar = res.data.url;
					// this.imageUrl = "http://gj.yandcj.cn/" + res.data.url;
					this.imageUrl = 'http://wu.12123jg.com.cn' + res.data
					this.user.avatar= res.data
				}else{
					this.$message.error(res.msg);
				}
				
			},
			beforeAvatarUpload(file) {
				let types = [
					"image/jpeg",
					"image/jpg",
					"image/gif",
					"image/png",
				];
				const isImage = types.includes(file.type);
				const isLtSize = file.size / 1024 / 1024 < 2;
				if (!isImage) {
					this.$message.error("上传图片只能是 JPG、JPEG、gif、bmp、PNG 格式!");
					return false;
				}
				if (!isLtSize) {
					this.$message.error("上传图片大小不能超过 2MB!");
					return false;
				}
				return true;
			},
		},
	};
</script>

<style lang="scss" scope>
	@media screen and (max-width: 480px) {
		.el-dialog__wrapper .el-dialog {
			width: 95% !important;
		}

		.el-dialog__wrapper .el-dialog .el-dialog__body {
			overflow: auto;
		}
	}

	@media screen and (max-width: 480px) {
		.el-message {
			min-width: 300px !important;
		}
	}

	.home {
		background: rgb(144, 112, 233);
		height: 1300px;
		width: 500px;
	}

	.warning-row {
		background: blue;
	}

	.success-row {
		background: red;
	}

	.avatar-uploader .el-upload {
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}

	.avatar-uploader .el-upload:hover {
		border-color: #409eff;
	}

	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 80px;
		height: 80px;
		line-height: 80px;
		text-align: center;
	}

	.avatarImg {
		width: 80px;
		height: 80px;
		display: block;
	}
</style>
