import {request} from "./request"

export function userInfo(data){
 
    return request({
        url:"/api/admin/user-manage/add",
        method:"post",
        data:data
    })
}
export function manageAdd(data){
    return request({
        url:"/api/admin/exam-appointment-manage/add",
        method:"post",
        data:data
    })
   
}
export function getList(data){
    return request({
        url:"/api/admin/user-manage/list",
        method:"get",
        params:data
    })
   
}
export function getMake(data){
    return request({
        url:"/api/admin/exam-appointment-manage/list",
        method:"get",
        params:data
    })
   
}
export function addSubject(data){
    return request({
        url:"/api/admin/achievement-manage/add",
        method:"post",
        data:data
    })
   
}
export function getSubject(data){
    return request({
        url:"/api/admin/achievement-manage/info",
        method:"get",
        params:data
    })
   
}


export function upload(data){
    return request({
        url:"/web/common/upload",
        method:"post",
        data:data
    })
}
export function deleteItem(data){
    return request({
        url:"api/admin/user-manage/delete",
        method:"delete",
        data:data
    })
}
export function editPass(data){
    return request({
        url:"/api/admin/rest-password",
        method:"post",
        data:data
    })
}
